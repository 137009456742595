import { Howl } from 'howler'
var frog = require('@/assets/audio/frog.mp3')
var cash = require('@/assets/audio/cash.mp3')
var splash = require('@/assets/audio/Splash.mp3')
var slap = require('@/assets/audio/Slap.mp3')
var SwordSwing = require('@/assets/audio/SwordSwing.mp3')
export default {
  methods: {
    playAudio(file) {
      var audio = new Howl({
        src: file
      })
      audio.play()
    },
    playCash() {
      this.playAudio(cash)
    },
    playFrog() {
      this.playAudio(frog)
    },
    playSplash() {
      this.playAudio(splash)
    },
    playSlap() {
      this.playAudio(slap)
    },
    playSwordSwing() {
      this.playAudio(SwordSwing)
    }
  }
}
